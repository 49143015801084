import Heading1 from "../../../components/text/Heading1";
import Heading2 from "../../../components/text/Heading2";
import "./Network.scss";
import Map from "./Map";

export default function Network({ refObj }) {
  return (
    <div ref={refObj} className="network" data-aos="fade-up">
      <Heading1 text1="Our" text2="Network" />
      <Heading2
        text1="We Are Not"
        text2="Only Present in maharashtra"
        style={{ textAlign: "center", textTransform: "capitalize" }}
      />
      <Heading1 text1="We" text2="Are ...." style={{ fontSize: "42px" }} />
      <div className="map">
        <Map />
      </div>
    </div>
  );
}
