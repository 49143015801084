import { Row, Col, Container } from "react-bootstrap";
import Heading1 from "../../text/Heading1";
import ContactForm from "./ContactForm";
import "./Contact.scss";

export default function Contact() {
  return (
    <Container className="contact" fluid={true} data-aos="fade-up">
      <Row noGutters>
        <Col lg="6" className="h-100">
          <Heading1 text1="Contact" text2="Us" />
          <ContactForm />
        </Col>
        <Col lg="6" className="h-100" align="center">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.963524471449!2d75.23546031444528!3d19.841496232620667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb9b11a7fdcdc9%3A0x30c256987fbbae44!2sMaks%20clean%20system!5e0!3m2!1sen!2sin!4v1617603606490!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            data-aos="zoom-in"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
}
