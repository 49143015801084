import "./Footer.scss";
import { Container, Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="footer">
      <Container fluid={true}>
        <Row>
          <Col lg="8">
            <h4 className="heading3">Quick links</h4>
            <p>
              <i className="ri-map-2-line"></i>
              <span>
                RL-36, Bajaj Nagar, Waluj,
                <br /> Aurangabad, Maharashtra 431133
              </span>
            </p>
          </Col>
          <Col lg="4">
            <h4 className="heading3">COMPANY POLICY</h4>
            <ul>
              <li>
                <a href="/terms_conditions.html">Terms &amp; Condition</a>
              </li>
              <li>
                <a href="/privacy_policy.html">Privacy Policy</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <span className="copyright">
        Copyright © 2021 · All Rights Reserved by Maks Clean Systems{" "}
      </span>
    </div>
  );
}
