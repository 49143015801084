import "./Leadership.scss";
import Heading1 from "../../text/Heading1";
import Heading2 from "../../text/Heading2";
import { Row, Col, Container, Image } from "react-bootstrap";
import leader from "../../../images/director.jpg";
import leaderSP from "../../../images/director_SP.JPG";
import team from "../../../images/team.jpg";

const backgroundList = [
  "Qualified chemical technologist’s from UDCT Mumbai",
  "Management graduate from LMI (London Management Instistute)",
  "40 years of presence in Pharmaceutical industry with FDA approval in sterile Dosages",
  "Hands On Knowledge and expertise in NABH compliances",
  "Stepped from pharma industry as CEO to venture into hospital industry",
];

const backgroundListSP = [
  "Qualified Mechanical Engineer",
  "Successful Enterpreneur of Auto component industry for last 20 years.",
  "Successful Enterpreneur of package drinking water industry.",
  "Plant setup of forging unit in Waluj, Aurangabad and with six automatic furnaces unit.",
];

const teamList = [
  "At MAKS CLEAN we believe that communication builds trust and trust builds cohesion",
  "We also believe that good flowers become effective leadership our team work is based on strong and symbiotic relationship, respect and sharing. each one of us is a astrnong foundation on which the entire team stands tall",
];

export default function Leadership() {
  return (
    <Container className="leadership" fluid>
      <Heading1
        text1="maks"
        text2="clean system leadership"
        style={{ fontSize: "32px", marginBottom: "8px" }}
      />
      <hr />
      <Row noGutters>
        <Col xs="12" lg="4">
          <div class="mobile">
            <Heading2 text1="Hello" text2="Everyone," />
            <Heading2 text1="Am Mukund Atre" text2="" />
          </div>
          <Image fluid src={leader} className="leader-img" />
          <caption>Director</caption>
        </Col>
        <Col xs="12" lg="8" className="leader-section">
          <div class="desktop">
            <Heading2 text1="Hello" text2="Everyone," />
            <Heading2 text1="Am Mukund Atre" text2="" />
          </div>
          <p>
            <ul>
              {backgroundList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </p>
        </Col>
      </Row>
      <Row noGutters style={{ marginTop: 36 }}>
        <Col
          xs="12"
          lg="8"
          className="leader-section"
          style={{ paddingLeft: 28 }}
        >
          <Heading2 text1="Hello" text2="Everyone," />
          <Heading2 text1="Am Shivaji Patale" text2="" />
          <div className="mobile">
            <Image fluid src={leaderSP} className="leader-img" />
            <caption>Director</caption>
          </div>
          <p>
            <ul>
              {backgroundListSP.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </p>
        </Col>
        <Col xs="12" lg="4">
          <div class="desktop">
            <Image fluid src={leaderSP} className="leader-img" />
            <caption>Director</caption>
          </div>
        </Col>
      </Row>
      <div className="details">
        {/* <Heading2 text1="My" text2="professional background" />
        <ul>
          {backgroundList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul> */}
        <Heading2 text1="Our" text2="team" />
        <Image fluid src={team} className="team-img" />
        <ul>
          {teamList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </Container>
  );
}
