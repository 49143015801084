import React from "react";
import { Form, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { init, send } from "emailjs-com";
import Alert from "@material-ui/lab/Alert";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function ContactForm() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [desc, setDesc] = React.useState("");

  const [progress, setProgress] = React.useState(false);

  const [toast, setToast] = React.useState({
    show: false,
    msg: "",
    type: "",
  });

  const sendMail = () => {
    if (!name.length)
      setToast({ show: true, msg: "Please enter your name.", type: "error" });
    else if (!validateEmail(email))
      setToast({
        show: true,
        msg: "Please enter valid email id.",
        type: "error",
      });
    else {
      setToast({
        show: false,
        msg: "",
        type: "",
      });
      setProgress(true);
      init(process.env.REACT_APP_USER_ID);
      send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        {
          name: name,
          email: email,
          description: desc,
        }
      )
        .then((result) =>
          setToast({
            show: true,
            msg: "Thank you for contacting us! We will get back to you soon.",
            type: "success",
          })
        )
        .catch((error) =>
          setToast({
            show: true,
            msg: "Failed to send details! Please try again after some time.",
            type: "error",
          })
        )
        .finally(() => setProgress(false));
    }
  };

  return (
    <Form className="contact-form">
      <TextField
        label="Name"
        type="search"
        variant="outlined"
        size="small"
        placeholder="Enter Your Full Name"
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
      <TextField
        label="E-mail"
        type="search"
        variant="outlined"
        size="small"
        placeholder="Enter Your Email ID"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Description"
        type="search"
        variant="outlined"
        size="small"
        placeholder="Enter Your Comments"
        margin="normal"
        multiline={true}
        fullWidth
        rows={4}
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
      />
      <Button
        className="button1 contact-button"
        onClick={sendMail}
        disabled={progress}
      >
        Submit <i className="ri-mail-send-line ml-2"></i>
      </Button>
      {toast.show ? <Alert severity={toast.type}>{toast.msg}</Alert> : ""}
    </Form>
  );
}
