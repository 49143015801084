import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "react-alice-carousel/lib/alice-carousel.css";

import React from "react";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init();

  const homeRef = useRef(null);
  const ourProduct = useRef(null);
  const ourNetwork = useRef(null);

  const refList = [homeRef, ourProduct, ourNetwork];

  // React.useEffect(() => {
  //   alert("Site is under construction.");
  // }, []);

  return (
    <BrowserRouter>
      <Navbar refList={refList} />
      <Switch>
        <Route exact path="/" component={() => <Home refList={refList} />} />
        <Route path="/about-us" component={AboutUs} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
