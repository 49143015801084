import React from "react";
import Landing from "./Landing";
import Products from "./Products";
import Features from "./Features";
import Gallery from "./Gallery";
import Network from "./Network/Network";
import Contact from "./Contact";

export default function Home({ refList }) {
  return (
    <div className="Home">
      <Landing refObj={refList[0]} />
      <Products refObj={refList[1]} />
      <Features />
      <Gallery />
      <Network refObj={refList[2]} />
      <Contact />
    </div>
  );
}
