import "./Certificates.scss";
// import img from "../../../images/certificates.png";
import { Image, Col, Row, Container } from "react-bootstrap";
import Heading from "../../text/Heading1";

const imgList = [];

export default function Certificates() {
  return (
    <div className="certificates">
      <Container>
        <Heading
          text1="company"
          text2="certificates"
          style={{ fontSize: "32px" }}
        />
        <Row>
          {imgList.map((item, index) => (
            <Col key={index} sm="6" lg="3">
              <Image fluid src={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
