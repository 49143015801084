import React from "react";

import img1 from "../../../images/asian_hospital.jpg";
import img2 from "../../../images/Aurangabad_Drisht_eye.jpg";
import img3 from "../../../images/indore.png";
import img4 from "../../../images/mamata-logo.gif";
// import img5 from "../../../images/Patil_Hospital.jfif";
import img6 from "../../../images/Zeel_Logo_Rajasthan.png";
import { Button, Container, Image } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import "./Landing.scss";
import ModalVideo from "react-modal-video";

const responsive = {
  0: { items: 2 },
  568: { items: 2 },
  1024: { items: 6 },
};

const sliderImages = [img1, img2, img3, img4, img6];

export default function Home({ refObj }) {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <div className="Landing" ref={refObj} data-aos="fade-out">
      <Container fluid data-aos="zoom-out">
        <h1>
          <span>THE Most</span>
          <br /> ADVANCE TREAMENT IN NOSOCOMIAL INFECTIONS WITH MODULATIONS
        </h1>
        <div className="sub-heading-home">
          <span className="vertical-line"></span>
          <p>
            In OT, ICU, NICU, PICU. we install clean rooms innovatively with
            technology support. We understand the end user’s requirement to find
            a solution and resolve it scientifically.
          </p>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="vYd7jY8fcbE"
          onClose={() => setOpen(false)}
        />
        <Button className="button1" onClick={() => setOpen(true)}>
          Watch Demo <i className="ri-play-circle-line ml-2"></i>
        </Button>
      </Container>
      <div className="customers" data-aos="fade-up">
        <p align="center">
          Over 100+ Hospital we served and happy with our services
        </p>
        <AliceCarousel
          mouseTracking
          items={sliderImages.map((img, index) => (
            <div className="customer-img" key={index}>
              <Image src={img} />
            </div>
          ))}
          infinite={true}
          autoPlay={true}
          responsive={responsive}
          animationDuration="1800"
          autoPlayInterval="2000"
          disableDotsControls={true}
        />
      </div>
    </div>
  );
}
