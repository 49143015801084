import React from "react";
import AboutUsLanding from "./AboutUsLanding";
import "./AboutUs.scss";
import Certificates from "./Certificates";
import Leadership from "./Leadership/Leadership";

export default function AboutUs() {
  return (
    <div className="about-us">
      <AboutUsLanding />
      <Certificates />
      <Leadership />
    </div>
  );
}
