import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ContactForm from "../Home/Contact/ContactForm";
import Heading1 from "../text/Heading1";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className="button1 mr-3" inline="true" onClick={handleClickOpen}>
        Contact Us <i className="ri-mail-send-line ml-2"></i>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          <Heading1
            text1="Contact"
            text2="Us"
            style={{ fontSize: "36px", margin: "0 auto", textAlign: "center" }}
          />
          {open ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icon>close</Icon>
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent style={{ padding: 0, paddingBottom: "15px" }}>
          <ContactForm />
        </DialogContent>
      </Dialog>
    </div>
  );
}
