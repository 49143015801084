import { Col, Container, Row } from "react-bootstrap";
import Heading1 from "../../text/Heading1";
import Heading2 from "../../text/Heading2";
// import aboutUsImg from "../../../images/about.png";
import "./AboutUsLanding.scss";

const listData = [
  "A perspective backed by a philosophy",
  "For us Excellence and integrity go hand in hand",
  "MAKS CLEAN is a innovative approach for reducing nosocomial infections in OT, ICU, NICU, PICU",
  "We install clean rooms innovatively with technology support",
  "We understand the end user’s requirement to find a solution and resolve it scientifically",
];

export default function AboutUsLanding() {
  return (
    <Container fluid className="about-us-landing">
      <Row noGutters>
        <Col xs="12" lg="12">
          {/* {window.innerWidth > 800 ? ( */}
          <Heading1 text1="About" text2="US" style={{ textAlign: "left" }} />
          {/* ) : (
            ""
          )} */}
          <Heading2
            text1="About"
            text2="our company"
            style={{ textAlign: "left" }}
          />
          <ul>
            {listData.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Col>
        {/* <Col xs="12" lg="6">
          {window.innerWidth < 800 ? (
            <Heading1 text1="About" text2="US" style={{ textAlign: "left" }} />
          ) : (
            ""
          )}
          <Image fluid src={aboutUsImg} style={{ width: "100%" }} />
        </Col> */}
      </Row>
    </Container>
  );
}
