import React, { useEffect } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import logo from "../../images/logo.png";
import logo_color from "../../images/logo_color.png";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import ContactDialog from "./ContactDialog";
import "./navbar.scss";

export default function CustomNavbar({ refList }) {
  const location = useLocation();
  const [isScrolled, setScrolled] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const toggleDrawer = () => setDrawer(!drawer);
  const closeDrawer = () => setDrawer(false);

  const navbarRef = React.useRef(null);

  useEffect(() => {
    if (location.state !== null) {
      executeScroll(location.state, 50);
    }
  }, [location]);

  // handle mobile drawer
  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 300) {
        setScrolled(true);
        navbarRef.current.style.position = "fixed";
      } else {
        setScrolled(false);
        navbarRef.current.style.position = "absolute";
      }
    });
  }, []);

  // handle home and about us scroll
  useEffect(() => {
    if (
      (location.pathname === "/" || location.pathname === "/about-us") &&
      !location.state
    )
      window.scrollTo(0, 0);
  }, [location]);

  const NavLinks = () => {
    return (
      <>
        <Nav className="ml-auto mr-5" onClick={closeDrawer}>
          {navLinks.map((item, index) => (
            <CustomNavLink {...item} refList={refList} key={index} />
          ))}
        </Nav>
        <ContactDialog />
      </>
    );
  };

  return (
    <Navbar
      ref={navbarRef}
      expand="lg"
      className={location.pathname !== "/" || isScrolled ? "navbar-white" : ""}
    >
      <NavLink className="ml-3" to="/" component={Navbar.Brand}>
        <Image
          src={location.pathname !== "/" || isScrolled ? logo_color : logo}
          rounded
        />
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleDrawer}>
        <i className="ri-menu-line"></i>
      </Navbar.Toggle>
      {window.innerWidth < 800 ? (
        <Drawer
          anchor="left"
          open={drawer}
          onClose={toggleDrawer}
          className="mobile-drawer"
        >
          <NavLink className="mr-auto ml-auto" to="/" onClick={closeDrawer}>
            <Image src={logo_color} rounded />
          </NavLink>
          <NavLinks />
        </Drawer>
      ) : (
        <Navbar.Collapse id="basic-navbar-nav">
          <NavLinks />
        </Navbar.Collapse>
      )}
    </Navbar>
  );
}

function CustomNavLink({ to, icon, title, refIndex, refList }) {
  const history = useHistory();
  const location = useLocation();

  const onClick = () => {
    if (location.pathname === "/") {
      executeScroll(refList[refIndex], -10);
    } else {
      history.push("/", refList[refIndex]);
    }
  };

  return to ? (
    <NavLink className="nav-link" to={to} activeClassName="active" exact>
      <i className={"ri-" + icon + "-line mr-2 line-icon"}></i>
      <i className={"ri-" + icon + "-fill mr-2 fill-icon"}></i> {title}
    </NavLink>
  ) : (
    <span className="nav-link" onClick={onClick}>
      <i className={"ri-" + icon + "-line mr-2 line-icon"}></i>
      <i className={"ri-" + icon + "-fill mr-2 fill-icon"}></i> {title}
    </span>
  );
}

const navLinks = [
  {
    title: "Home",
    icon: "home-2",
    to: "/",
    refIndex: 0,
  },
  {
    title: "Our Products",
    icon: "service",
    refIndex: 1,
  },
  {
    title: "About Us",
    icon: "shield-user",
    to: "/about-us",
  },
  {
    title: "Our Network",
    icon: "earth",
    refIndex: 2,
  },
];

// on click event function for navbar
function executeScroll(ref, offset) {
  if (ref && ref.current)
    window.scrollTo({
      top: ref.current.offsetTop + offset,
      left: 0,
      behavior: "smooth",
    });
}
