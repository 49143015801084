import Heading1 from "../../../components/text/Heading1";
import FeatureCard from "./FeatureCard";
import { Row, Col, Container } from "react-bootstrap";
import "./Features.scss";

import bioReduction from "../../../images/image9.png";
import ambulance from "../../../images/quality.png";
import alarmSystem from "../../../images/alarm-system.png";
import clock from "../../../images/clock.png";
import hygiene from "../../../images/hygiene.png";
// import examiningTable from "../../../images/examining-table.png";

const featuresList = [
  {
    title: "Bioburden Reduction",
    img: bioReduction,
  },
  {
    title: "Emergency Assistance",
    img: ambulance,
  },
  {
    title: "Auto - OT Control with Alarm System",
    img: alarmSystem,
  },
  {
    title: "Real - time Accountabilty for Required Entries",
    img: clock,
  },
  {
    title: "Hygiene Compliance Mask Dispensor",
    img: hygiene,
  },
  // {
  //   title: "Elevated Profile with OT Table.",
  //   img: examiningTable,
  // },
];

export default function Features() {
  return (
    <Container className="features" fluid data-aos="fade-up">
      <Heading1 text1="OUR" text2="USP" />
      <Row className="features-row">
        {featuresList.map((feature, index) => (
          <Col lg="4" sm="6" className="h-100" key={index}>
            <FeatureCard {...feature} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
