import React from "react";
import Heading1 from "../../text/Heading1";
import Heading2 from "../../text/Heading2";
import { Row, Col, Image, Container } from "react-bootstrap";
import OT from "../../../images/OT.jpg";
import surgeon_pendant from "../../../images/surgeon_pendant (1).jpg";
import anaesthesia_pendent from "../../../images/pendant.jpg";
import beds from "../../../images/ot_bed.jpg";
import pass_box from "../../../images/pass_box2.jpg";
import AliceCarousel from "react-alice-carousel";
import ProductDetails from "./ProductDetails";
import "./Products.scss";

const sliderImages = [OT, surgeon_pendant, anaesthesia_pendent, beds, pass_box];

export default function Products({ refObj }) {
  const [carousel, setCarousel] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [expand, setExpand] = React.useState(false);

  const nextSlide = (e) => {
    carousel.slidePrev();
    carousel.slideNext();
    setCurrentSlide((currentSlide + 1) % sliderImages.length);
  };

  const prevSlide = (e) => {
    if (currentSlide === 0) setCurrentSlide(sliderImages.length - 1);
    else setCurrentSlide((currentSlide - 1) % sliderImages.length);
  };

  const slideTo = (e, index) => {
    carousel.slideTo(index);
    setCurrentSlide(index);
  };

  const toggleDesc = () => {
    setExpand(!expand);
  };

  // React.useEffect(() => {
  //   // if (expand) carousel.state.isAutoPlaying = false;
  // }, [carousel, expand]);

  return (
    <Container fluid className="products" ref={refObj} data-aos="fade-up">
      <Heading1 text1="Our" text2="modular in ss & PPGI" />
      <Row noGutters>
        <Col lg="6" className="slider-content">
          <Heading2
            text1={productsData[currentSlide].title.text1}
            text2={productsData[currentSlide].title.text2}
          />
          <p className="para1">{productsData[currentSlide].info}</p>
          <p
            className="sub-heading1"
            onClick={toggleDesc}
            style={{
              display:
                expand || !productsData[currentSlide].desc.length
                  ? "none"
                  : "block",
            }}
          >
            <u>Read More</u>
          </p>
        </Col>
        <Col
          xs="12"
          style={{ display: window.innerWidth < 500 ? "block" : "none" }}
        >
          <ul className="custom-slider-list">
            {productsData.map((img, index) => (
              <li
                key={index}
                className={currentSlide === index ? "active" : ""}
                onClick={(e) => slideTo(e, index)}
              />
            ))}
          </ul>
        </Col>
        <Col lg="6">
          <AliceCarousel
            mouseTracking
            items={productsData.map((productData, index) => (
              <Image
                src={productData.img}
                className="img-fluidcor-img"
                key={index}
              />
            ))}
            paddingRight={90}
            paddingLeft={90}
            infinite={true}
            animationDuration={2000}
            disableButtonsControls={window.innerWidth < 500 ? false : true}
            disableDotsControls={true}
            activeIndex={currentSlide}
            ref={(el) => setCarousel(el)}
            onSlideChanged={(e) => {
              setCurrentSlide(e.slide);
            }}
            autoPlayStrategy="all"
            autoPlay={!expand}
            autoPlayInterval={2000}
          />
        </Col>
      </Row>
      {productsData[currentSlide].desc.length ? (
        <ProductDetails
          desc={productsData[currentSlide].desc}
          display={expand}
          toggleDisplay={toggleDesc}
        />
      ) : (
        ""
      )}
      <div className="custom-slider-arrow">
        <i className="ri-arrow-right-circle-line" onClick={nextSlide}></i>
        <span
          style={{ letterSpacing: currentSlide === 0 ? "0.15em" : "0.05em" }}
        >
          0{currentSlide + 1}
        </span>
        <i className="ri-arrow-left-circle-line" onClick={prevSlide}></i>
      </div>
      <ul
        className="custom-slider-list"
        style={{ display: window.innerWidth < 500 ? "none" : "block" }}
      >
        {productsData.map((img, index) => (
          <li
            key={index}
            className={currentSlide === index ? "active" : ""}
            onClick={(e) => slideTo(e, index)}
          />
        ))}
      </ul>
    </Container>
  );
}

const productsData = [
  {
    img: OT,
    title: {
      text1: "MODULAR",
      text2: "OPERATION THEATRE",
    },
    info: "An Modular operation theatre or operation suite is a facility within a hospital where surgical operations are carried out in a sterile environment. Operating rooms are peripheral clean room lights, easy to clean, and with double firm x-rays viewer & sealing suspended, viewing screens and monitors for endoscopy, laparoscopy, etc.",
    desc: [
      "Restricted entry cubicle with data entry of personnel following entry protocols only",
      "Modular ICUs with automation to control Nosocomial infections.",
      "Supporting AHU System",
    ],
  },
  {
    img: surgeon_pendant,
    title: {
      text1: "Surgeon's O.T.",
      text2: "Pendant with Extended LCD Arm",
    },
    info: "Aluminium Extruded Single Arm Ceiling Suspended Unit for Operation Theatres. Multi movements with high load-bearing capacity. 330° Arm and console rotation Console size (H) 900 mm, (W) 50mm, (D) 150 mm. Additional LCD arm for keeping monitor. Provision for Gas Outlets & Electrical Switch/ Sockets. 6 trays for keeping accessories on the vertical rail.",
    desc: [
      "Revolving Monitor Tray with Clamp.",
      "Vertical Rail 4 Feet.",
      "Storage Basket with Clamp.",
      "Syringe Pump Attachment with Clamp. IV Stand with Clamps.",
      "Provisions for Gas Outlets & Electrical Switch Sockets can be customised. Various models are available.",
    ],
  },
  {
    img: anaesthesia_pendent,
    title: {
      text1: "Anaesthesia Pendant /",
      text2: "Surgeons Pendant",
    },
    info: (
      <>
        Aluminium Extruded Ceiling Suspended Unit Specially designed for
        Anaesthetist in operation Theatre. The Customised pendant is available
        in two variants -{" "}
        <ul>
          <li>Anaesthesia Pendant - Rigid</li>
          <li>Anaesthesia Pendant - with Extended Arm</li>
          <li>Surgeons Pendant</li>
        </ul>
      </>
    ),
    desc: [
      "This Anaesthesia Pendant has provisions for Gas outlets, Electrical Switch Sockets & Data Points with accessories Like Monitor Tray, IV Stand and Storage Basket etc.",
    ],
  },
  {
    img: beds,
    title: {
      text1: "Horizontal",
      text2: "Bed Head Panels",
    },
    info: "Aluminium Extruded Bed Head Panel available in various sizes and colours, Where all gas connections along with electrical switches, nurse call systems etc comes in one panel which is mounted on the wall at working height.",
    desc: [],
  },
  {
    img: pass_box,
    title: {
      text1: "Pass",
      text2: "Box",
    },
    info: (
      <>
        Material transfer under UV - Radiation unit with interlock door
        arrangements.
        <br />
        <br />
        There are two types:
        <ol type="a">
          <li>Manual Pass Box</li>
          <li>Magnetic Pass Box</li>
        </ol>
      </>
    ),
    desc: [],
  },
];
