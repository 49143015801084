import { Container } from "react-bootstrap";
import Heading1 from "../../text/Heading1";

export default function ProductDetails({ desc, display, toggleDisplay }) {
  return (
    <Container
      fluid
      className="product-details"
      style={{ display: display ? "block" : "none" }}
    >
      <Heading1
        text1="Product"
        text2="Features"
        style={{ textAlign: "left", fontSize: "32px" }}
      />
      <ul>
        {desc.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <Heading1
        text1="Modular"
        text2="Range"
        style={{ textAlign: "left", fontSize: "32px" }}
      />
      <ul style={{ columns: "2" }}>
        {modularRange.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p className="sub-heading1" onClick={toggleDisplay}>
        Read Less
      </p>
    </Container>
  );
}

const modularRange = [
  "Cardiac OT",
  "Neuro OT",
  "Ortho OT",
  "Endoscopic",
  "General Surgery",
  "ICU",
  "NICU",
  "Dialysis Section ",
  "CSSD - Design & Layout",
];
