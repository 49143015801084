import React from "react";
import "./Gallery.scss";
import Heading1 from "../../text/Heading1";

const images = require.context("../../../images/gallery", true);
const imgs = [
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_0091.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151036.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151039.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151049.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151056.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151120.jpg",
  },
  {
    tag: "Air Handling Unit Control Panel",
    image: "Air Handling Unit Control Panel/IMG_20210903_151132.jpg",
  },
  {
    tag: "Door",
    image: "Door/1629886900585.jpg",
  },
  {
    tag: "Door",
    image: "Door/1629886900637.jpg",
  },
  {
    tag: "Door",
    image: "Door/1629886900659.jpg",
  },
  {
    tag: "Door",
    image: "Door/1629886900704.jpg",
  },
  {
    tag: "ICU",
    image: "ICU/IMG_20210918_132513_1.jpg",
  },
  {
    tag: "ICU",
    image: "ICU/IMG_20210918_132522.jpg",
  },
  {
    tag: "ICU",
    image: "ICU/IMG_20210918_132530.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_0135.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_111001.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_111845.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_115009.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_115033.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_115049.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_115248_1.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_130428.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_131036.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210903_131127.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210904_144107.jpg",
  },
  {
    tag: "Modular OT PPGI",
    image: "Modular OT PPGI/IMG_20210904_144237.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165209.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165221.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165345.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165412.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165432.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165446.jpg",
  },
  {
    tag: "Modular OT SS",
    image: "Modular OT SS/IMG_20210919_165455.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_0105.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_130443.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_130453.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_131358.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_143040.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_143047.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210903_151426.jpg",
  },
  {
    tag: "OT Complex",
    image: "OT Complex/IMG_20210905_121447.jpg",
  },
  {
    tag: "Pendant",
    image: "Pendant/IMG_20210903_115420.jpg",
  },
  {
    tag: "Pendant",
    image: "Pendant/IMG_20210904_163548.jpg",
  },
];

const filters = [
  { name: "Air Handling Unit Control Panel", status: false },
  { name: "Door", status: false },
  { name: "ICU", status: false },
  { name: "Modular OT PPGI", status: false },
  { name: "Modular OT SS", status: false },
  { name: "OT Complex", status: false },
  { name: "Pendant", status: false },
];

const Filters = ({ onClickAll, all, onClick, filters }) => (
  <form>
    <ul>
      <li onClick={onClickAll}>
        <input type="checkbox" checked={all} />
        <label htmlFor="all">All</label>
      </li>
      {filters.map((filter, i) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input id={filter.name} type="checkbox" checked={filter.status} />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

const Cards = ({ imgs }) => (
  <ul>
    {imgs.map((img, i) => (
      <li key={i}>
        <figure>
          <img
            src={images(`./${img.image}`).default}
            alt={img.author}
            loading="lazy"
          />
          <figcaption>{img.tag}</figcaption>
        </figure>
      </li>
    ))}
  </ul>
);

export default class Gallery extends React.Component {
  state = {
    imgs,
    filters,
    all: true,
  };

  setFilter = (e) => {
    e.preventDefault();
    const { filters } = this.state;
    const { index } = e.currentTarget.dataset;
    filters.forEach((filter) => (filter.status = false));
    filters[index].status = !filters[index].status;
    this.setState({
      filters,
    });

    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters } = this.state;
    let newImgs = [];
    let a = 0;

    imgs.forEach((img, imgKey) => {
      filters.forEach((filter, filterKey) => {
        if (img.tag === filter.name && filter.status === true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }

  render() {
    const { filters, all } = this.state;
    return (
      <div className="gallery">
        <Heading1 text1="Our" text2="Gallery" />
        <Filters
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters}
        />
        <div className="gallery-container">
          {all ? <Cards imgs={imgs} /> : <Cards imgs={this.state.imgs} />}
        </div>
      </div>
    );
  }
}
